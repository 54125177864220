import React from 'react'
import './TitleBasic.css'

const TitleBasic = (props) => {
  return (
    <>
      <h3 className='titlebasic' >{props.title}</h3>
    </>
  )
}

export default TitleBasic
